/* Home.css */
.home-container {
    text-align: center;
    color: #333;
    padding: 20px;
  }
  
  .home-container h1 {
    font-size: 2.5rem;
  }
  
  .home-container p {
    font-size: 1.2rem;
  }
  